import { Auth } from "aws-amplify"
import jwt_decode from "jwt-decode"
import { privileges } from "@/privileges"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"

export function getAuthUser() {
  return Auth.currentAuthenticatedUser()
}

export function getTokenFromAuthUser(authUser) {
  return authUser.signInUserSession.idToken.jwtToken
}

export async function getSdeUserIdFromAuthUser() {
  const authUser = await getAuthUser()
  return authUser.attributes.name
}

export async function getAuthUserPrivileges() {
  const user = await getAuthUser()
  const token = jwt_decode(user.signInUserSession.accessToken.jwtToken)

  // only the first role will be returned, multiple groups are not supported yet
  return privileges.filter(({ role }) => token["cognito:groups"].includes(role))[0]
}

export async function getCognitoIdentityCredentials() {
  const { aws_user_pools_id, aws_cognito_region, aws_cognito_identity_pool_id } = window.aws_data

  const user = await getAuthUser()
  const jwtToken = getTokenFromAuthUser(user)

  return fromCognitoIdentityPool({
    clientConfig: { region: aws_cognito_region },
    identityPoolId: aws_cognito_identity_pool_id,
    logins: {
      [`cognito-idp.${aws_cognito_region}.amazonaws.com/${aws_user_pools_id}`]: jwtToken,
    },
  })
}
