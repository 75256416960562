<template>
  <v-card v-bind="$attrs" :class="spacingClass">
    <v-card-title v-if="$slots.title" :class="titleClass">
      <slot name="title" />
    </v-card-title>
    <v-card-text>
      <slot name="content" />
    </v-card-text>
    <v-card-actions v-if="$slots.actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "BackofficeArea",
  props: {
    titleClass: {
      type: String,
      default: "",
    },

    spacingClass: {
      default: "mb-6",
    },
  },
}
</script>
