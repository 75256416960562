<template>
  <v-app>
    <v-app-bar v-if="signedIn" app>
      <v-container>
        <div class="d-flex align-center">
          <router-link :to="routes.HOME">
            <v-img alt="Vuetify Logo" class="shrink mr-2" contain :src="require('./assets/sellwerk-logo.svg')" height="40" />
          </router-link>
          <v-toolbar-title> Backoffice </v-toolbar-title>

          <div class="ml-auto">
            <amplify-sign-out button-text="Ausloggen" />
          </div>
        </div>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-container v-if="signedIn && backLinkVisible()">
        <v-btn tile outlined color="secondary" small data-test="page-back-button" @click="() => $router.back()"> Zurück </v-btn>
      </v-container>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import routes from "./routes"
import { Hub } from "aws-amplify"
import { getAuthUser } from "./helpers/auth"

export default {
  name: "App",
  data: () => ({
    signedIn: false,
    routes,
  }),
  beforeCreate() {
    Hub.listen("auth", async (data) => {
      const { payload } = data

      if (payload.event === "signIn") {
        this.$data.signedIn = true

        // Setting the cognito identity credentials to config on signIn
        await this.$router.push(routes.HOME)
      }

      if (payload.event === "signOut") {
        await this.$router.push(routes.AUTH)
        this.$data.signedIn = false
      }
    })

    getAuthUser()
      .then(async () => {
        this.$data.signedIn = true
      })
      .catch(() => {
        const { path } = this.$router.currentRoute

        if (path !== routes.AUTH) {
          this.$router.push(routes.AUTH)
        }

        this.$data.signedIn = false
      })
  },
  methods: {
    backLinkVisible() {
      const { path } = this.$router.currentRoute

      return path !== routes.HOME && path !== routes.PAGE_NOT_FOUND
    },
  },
}
</script>

<style>
@import "../node_modules/noty/lib/noty.css";
@import "../node_modules/noty/lib/themes/relax.css";

:root {
  --body-font-family: "Barlow", sans-serif;
  --color-error: #ff5a5f;
  --color-main_background: #f7f7f7;
  --color-main_background-alternative: #f8fafe;
  --box-shadow-card: 0 2px 16px #3d4c5e1a;
  --shadow-header: 0px 2px 16px rgba(61, 76, 94, 0.1);
  --spacing-large: 32px;
  --spacing-medium: 20px;
  --spacing-small: 8px;
  --spacing-smallest: 4px;
}

body,
.v-application {
  font-family: var(--body-font-family);
}

h1 small,
h2 small,
h3 small {
  color: var(--v-light-darken4);
  font-size: 60%;
  display: inline-block;
  margin-left: 5px;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: var(--v-light-base);
  box-shadow: var(--shadow-header);
}

.theme--light.v-application {
  background-color: var(--color-main_background);
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: var(--box-shadow-card);
}
</style>
