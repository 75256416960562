import VueRouter from "vue-router"
import { checkPrivilegesForArea } from "../helpers/general"
import { getAuthUserPrivileges, getAuthUser } from "../helpers/auth"

import routes from "../routes"
import { areas } from "../privileges"

import Home from "../views/BackofficeHome.vue"
import PageNotFound from "../views/PageNotFound"
import BackofficeAuthenticator from "@/components/BackofficeAuthenticator.vue"

function checkPrivilegesForAreaBeforeEnter(area, next) {
  getAuthUserPrivileges().then((privileges) => {
    if (!checkPrivilegesForArea(area, privileges)) {
      next(routes.PAGE_NOT_FOUND)
    } else {
      next()
    }
  })
}

const router = new VueRouter({
  routes: [
    {
      path: routes.HOME,
      component: Home,
      meta: { requiresAuth: true },
    },
    {
      path: routes.AUTH,
      component: BackofficeAuthenticator,
    },
    {
      path: routes.PAGE_NOT_FOUND,
      component: PageNotFound,
    },
    {
      path: routes.COMPANY,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (company.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "company" */ "../views/Company/CompanyOverview.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.company, next),
    },
    {
      path: routes.COMPANY_DETAIL,
      name: "company-detail",
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (company.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "company" */ "../views/Company/CompanyDetail.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.company, next),
    },
    {
      path: routes.ACCOUNT,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (account.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "account" */ "../views/Account/AccountDetail.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.account, next),
    },
    {
      path: routes.ORDER_IMPORT,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (orderimport.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "orderimport" */ "../views/OrderImport/OrderImportDetail.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.orderImport, next),
    },
    {
      path: routes.DLQ,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (dlq.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "dlq" */ "../views/DLQ/DLQOverview.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.dlq, next),
    },
    {
      path: routes.DLQ_DETAIL,
      name: "dlq-detail",
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (dlq.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "dlq" */ "../views/DLQ/DLQDetail.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.dlq, next),
    },
    {
      path: routes.BACKOFFICE_USER,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (backoffice-user.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "backoffice-user" */ "../views/BackofficeUser/BackofficeUserOverview.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.backofficeUser, next),
    },
    {
      path: routes.BACKOFFICE_USER_CREATE,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (backoffice-user.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "backoffice-user" */ "../views/BackofficeUser/BackofficeUserDetail.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.backofficeUser, next),
    },
    {
      path: routes.BACKOFFICE_USER_UPDATE,
      name: "backoffice-user-update",
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (backoffice-user.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "backoffice-user" */ "../views/BackofficeUser/BackofficeUserDetail.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.backofficeUser, next),
    },
    {
      path: routes.HISTORIZATION,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (user.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "historization" */ "../views/Historization/HistorizationOverview.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.historization, next),
    },
    {
      path: routes.DELETE_COMPANY,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (user.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "historization" */ "../views/DeleteCompany/DeleteCompanyOverview.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.deleteCompany, next),
    },
    {
      path: routes.SELLWERK_TRUSTED,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (user.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "sellwerkTrusted" */ "../views/SellwerkTrusted/SellwerkTrustedOverview.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.sellwerkTrusted, next),
    },
    {
      path: routes.RECOMMENDATIONS_DETAILS,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (user.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "recommendationDetails" */ "../views/SellwerkTrusted/RecommendationDetails.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.sellwerkTrusted, next),
    },
    {
      path: routes.INVOICES,
      meta: { requiresAuth: true },
      // route level code-splitting
      // this generates a separate chunk (user.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "sellwerkTrusted" */ "../views/Invoices/InvoicesOverview.vue"),
      beforeEnter: (to, from, next) => checkPrivilegesForAreaBeforeEnter(areas.invoices, next),
    },
    {
      path: "*",
      name: "pagenotfound",
      meta: { requiresAuth: true },
      redirect: routes.PAGE_NOT_FOUND,
    },
  ],
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    getAuthUser()
      .then(() => {
        next()
      })
      .catch(() => {
        next({
          path: "/auth",
        })
      })
  }
  next()
})

export default router
