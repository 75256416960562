<template>
  <v-container>
    <h1>404, Seite nicht gefunden.</h1>

    <p>Zurück zur <router-link :to="routes.HOME" data-test="pagenotfound-back-link">Startseite</router-link>.</p>
  </v-container>
</template>

<script>
import routes from "../routes"

export default {
  data: () => ({
    routes,
  }),
}
</script>
