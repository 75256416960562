<template>
  <v-container v-if="authUser">
    <h1>Willkommen, {{ authUser.attributes.firstName }} {{ authUser.attributes.lastName }}</h1>

    <v-row class="mt-2">
      <v-col v-if="checkPrivilegesForArea(areas.account)" md="6">
        <BackofficeArea :spacing-class="false">
          <template #title>Account</template>
          <template #content> Administration von SDE-Benutzer-Daten. </template>
          <template #actions>
            <v-btn tile text color="primary" data-test="customer-user-area-button" @click="() => getRouter.push(routes.ACCOUNT)"> Aufrufen </v-btn>
          </template>
        </BackofficeArea>
      </v-col>
      <v-col v-if="checkPrivilegesForArea(areas.company)" md="6">
        <BackofficeArea :spacing-class="false">
          <template #title>Unternehmensdaten</template>
          <template #content> Daten eines Unternehmens bearbeiten und Bilder hochladen. </template>
          <template #actions>
            <v-btn tile text color="primary" data-test="company-area-button" @click="() => getRouter.push(routes.COMPANY)"> Aufrufen </v-btn>
          </template>
        </BackofficeArea>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="checkPrivilegesForArea(areas.orderImport)" md="6">
        <BackofficeArea :spacing-class="false">
          <template #title>OrderImport</template>
          <template #content> Manueller OrderImport. </template>
          <template #actions>
            <v-btn tile text color="primary" data-test="order-area-button" @click="() => getRouter.push(routes.ORDER_IMPORT)"> Aufrufen </v-btn>
          </template>
        </BackofficeArea>
      </v-col>
      <v-col v-if="checkPrivilegesForArea(areas.dlq)" md="6">
        <BackofficeArea :spacing-class="false">
          <template #title>DLQ</template>
          <template #content> Dead letter queue Entwickler-Werkzeug. </template>
          <template #actions>
            <v-btn tile text color="primary" data-test="dlq-area-button" @click="() => getRouter.push(routes.DLQ)"> Aufrufen </v-btn>
          </template>
        </BackofficeArea>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="checkPrivilegesForArea(areas.backofficeUser)" md="6">
        <BackofficeArea :spacing-class="false">
          <template #title>Backoffice-Benutzer</template>
          <template #content> Backoffice-Benutzer anlegen und editieren. </template>
          <template #actions>
            <v-btn tile text color="primary" data-test="backoffice-user-area-button" @click="() => getRouter.push(routes.BACKOFFICE_USER)"> Aufrufen </v-btn>
          </template>
        </BackofficeArea>
      </v-col>
      <v-col v-if="checkPrivilegesForArea(areas.historization)" md="6">
        <BackofficeArea :spacing-class="false">
          <template #title>Änderungshistorie</template>
          <template #content>Historieneinträge anzeigen.</template>
          <template #actions>
            <v-btn tile text color="primary" data-test="historization-area-button" @click="() => getRouter.push(routes.HISTORIZATION)"> Aufrufen </v-btn>
          </template>
        </BackofficeArea>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="checkPrivilegesForArea(areas.deleteCompany)" md="6">
        <BackofficeArea :spacing-class="false">
          <template #title>Unternehmen löschen</template>
          <template #content> Doppelt angelegtes Unternehmen (company) löschen. </template>
          <template #actions>
            <v-btn tile text color="primary" data-test="delete-duplicate-company-area-button" @click="() => getRouter.push(routes.DELETE_COMPANY)"> Aufrufen </v-btn>
          </template>
        </BackofficeArea>
      </v-col>

      <v-col v-if="checkPrivilegesForArea(areas.sellwerkTrusted)" md="6">
        <BackofficeArea :spacing-class="false">
          <template #title>SELLWERK Trusted</template>
          <template #content> Informationen zu SWT anzeigen </template>
          <template #actions>
            <v-btn tile text color="primary" data-test="sellwerk-trusted-area-button" @click="() => getRouter.push(routes.SELLWERK_TRUSTED)"> Aufrufen </v-btn>
          </template>
        </BackofficeArea>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <BackofficeArea :spacing-class="false">
          <template #title>Rechnungen</template>
          <template #content>Rechnungen anzgeigen</template>
          <template #actions>
            <v-btn tile text color="primary" data-test="invoices-area-button" @click="() => getRouter.push(routes.INVOICES)"> Aufrufen </v-btn>
          </template>
        </BackofficeArea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { checkPrivilegesForArea } from "../helpers/general"
import { getAuthUser, getAuthUserPrivileges } from "../helpers/auth"
import routes from "../routes"
import { areas } from "../privileges"

import BackofficeArea from "../components/BackofficeArea"

export default {
  components: { BackofficeArea },
  data: () => ({
    authUser: null,
    userPrivileges: null,
    routes,
    areas,
  }),
  computed: {
    getRouter() {
      return this.$router
    },
  },
  async beforeMount() {
    const authUser = await getAuthUser()
    const privileges = await getAuthUserPrivileges()

    this.$data.authUser = authUser
    this.$data.userPrivileges = privileges
  },
  methods: {
    checkPrivilegesForArea(area) {
      return checkPrivilegesForArea(area, this.$data.userPrivileges)
    },
  },
}
</script>
