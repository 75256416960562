import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import de from "vuetify/es5/locale/de"

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 414 + 1,
      sm: 845 + 1,
      md: 1024 + 1 + 16,
      lg: 1280 + 1 + 16,
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#00AF9C",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5A5F",
        info: "#F7C01A",
        success: "#00AF9C",
        warning: "#F7C01A",
        disabled: "#D9E0E6",
        light: "#FFFFFF",
      },
    },
  },
  global: {
    ripples: false,
  },
  lang: {
    locales: { de },
    current: "de",
  },
})
