import Vue from "vue"
import VueRouter from "vue-router"
import { Auth } from "aws-amplify"

import vuetify from "./plugins/vuetify"
import router from "./plugins/router"

import "@aws-amplify/ui-vue"
import App from "./App.vue"

Auth.configure(window.aws_data)

Vue.use(VueRouter)
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app")
