import Noty from "noty"

export const isDevelopment = process && process.env.NODE_ENV === "development"

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return "0 Bytes"
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export function checkPrivilegesForArea(area, userPrivileges) {
  const { access, role } = userPrivileges

  if (role === "admin") {
    return true
  }

  const matches = access.filter(({ area: accessArea }) => accessArea === area)

  return matches.length > 0
}

export function checkPrivilegesForAreaAllow(area, allow, userPrivileges) {
  if (!checkPrivilegesForArea(area, userPrivileges)) {
    return false
  }

  const { access, role } = userPrivileges

  if (role === "admin") {
    return true
  }

  const matches = access.filter(({ area: accessArea, allow: accessAllow }) => area === accessArea && (accessAllow.includes("all") || accessAllow.includes(allow)))

  return matches.length > 0
}

export function showNotification(options = {}) {
  // https://ned.im/noty/#/types
  // https://ned.im/noty/#/options
  if (options.type === "error") {
    return new Noty({
      closeWith: ["button"],
      ...options,
      theme: "relax",
    }).show()
  } else {
    return new Noty({
      timeout: 10000,
      ...options,
      theme: "relax",
    }).show()
  }
}

export function generatePassword() {
  const LOWER_CASE_CHARS = "abcdefghijklmnopqrstuvwxyz"
  const UPPER_CASE_CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const DIGITS = "1234567890"
  const SPECIAL_CHARS = "._-"
  const MAX_CHARS = 5
  const MAX_SPECIAL_CHARS = 3

  let password = ""

  for (let i = 0; i < MAX_CHARS; i++) {
    password += LOWER_CASE_CHARS.charAt(Math.floor(Math.random() * LOWER_CASE_CHARS.length))
    password += UPPER_CASE_CHARS.charAt(Math.floor(Math.random() * UPPER_CASE_CHARS.length))
    password += DIGITS.charAt(Math.floor(Math.random() * DIGITS.length))

    if (i < MAX_SPECIAL_CHARS) {
      password += SPECIAL_CHARS.charAt(Math.floor(Math.random() * SPECIAL_CHARS.length))
    }
  }

  password = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("")

  return password
}

export const pad = (n) => n.toString().padStart(2, "0")

export function showRequestError(request) {
  const { response } = request

  if (!response) {
    showNotification({
      type: "error",
      text: request.message,
      timeout: false,
    })

    return request
  }

  const { errors, title } = response.data

  if (errors) {
    const errorList = []

    errors.forEach(({ reason }) => errorList.push(reason))

    showNotification({
      type: "error",
      text: errorList.join(" "),
      timeout: false,
    })
  } else {
    showNotification({
      type: "error",
      text: title,
      timeout: false,
    })
  }

  return request
}

const readImageAsDataUrl = async (image) => {
  return await new Promise((resolve, reject) => {
    let fileReader = new FileReader()

    fileReader.onload = () => resolve(fileReader.result)
    fileReader.onerror = fileReader.onabort = (e) => reject(e)

    fileReader.readAsDataURL(image)
  })
}

export const checkForInvalidImages = async (files, MIN_PIXEL_SIZE, MAX_PIXEL_SIZE) => {
  const fileReaderResults = files.map(readImageAsDataUrl)
  const invalidImages = []

  await Promise.allSettled(fileReaderResults).then(async (results) => {
    const imageResults = results.map((result) => result.value)

    const loadedImages = await Promise.all(
      imageResults.map((imageData) => {
        return new Promise((resolve) => {
          const img = new Image()
          img.onload = () => resolve(img)
          img.src = imageData
        })
      }),
    )

    loadedImages.forEach((img, i) => {
      if (img.width > MAX_PIXEL_SIZE || img.height > MAX_PIXEL_SIZE || img.width < MIN_PIXEL_SIZE || img.height < MIN_PIXEL_SIZE) {
        invalidImages.push(files[i])
      }
    })
  })

  return invalidImages
}

export const checkIfEmpty = (value) => {
  if (typeof value === "object") {
    return !Object.values(value).length || Object.values(value).every((value) => !value || checkIfEmpty(value))
  } else if (Array.isArray(value)) {
    return !value.length || value.some(checkIfEmpty)
  } else {
    return value === "" || value === undefined || value === null
  }
}

// Backend doesn't allow empty strings and expects the prop not to be present or null
export const replaceEmptyWithNull = (obj) => {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      for (const arrKey in obj[key]) {
        replaceEmptyWithNull(obj[key][arrKey])
      }
    }
    if (typeof obj[key] === "object") {
      replaceEmptyWithNull(obj[key])
    } else if (checkIfEmpty(obj[key])) {
      obj[key] = null
    }
  }
  return obj
}

export const padGpId = (value) => value.padStart(10, "0")
