<template>
  <amplify-authenticator>
    <amplify-sign-in slot="sign-in" header-text="Willkommen im SELLWERK Backoffice" :hide-sign-up="true" />
  </amplify-authenticator>
</template>

<script>
export default {
  name: "BackofficeAuthenticator",
}
</script>

<style>
/* Amplify theme configuration, see https://docs.amplify.aws/ui/customization/theming/q/framework/vue */
:root {
  --amplify-font-family: var(--body-font-family);
  --amplify-primary-color: var(--v-primary-base);
  --amplify-primary-contrast: var(--amplify-white);
  --amplify-primary-tint: var(--amplify-primary-color);
  --amplify-primary-shade: var(--amplify-primary-color);
  --amplify-secondary-color: var(--v-secondary-base);
  --amplify-secondary-contrast: var(--amplify-white);
  --amplify-secondary-tint: var(--amplify-secondary-color);
  --amplify-secondary-shade: var(--amplify-secondary-color);
  --amplify-tertiary-color: var(--v-accent-base);
  --amplify-tertiary-contrast: var(--amplify-white);
  --amplify-tertiary-tint: var(--amplify-tertiary-color);
  --amplify-tertiary-shade: var(--amplify-tertiary-color);
  --amplify-grey: var(--amplify-secondary-color);
  --amplify-light-grey: var(--v-disabled-base);
  --amplify-white: var(--v-light-base);
  --amplify-red: var(--v-error-base);
}
</style>
